<template>
        <div class="grid-x" :style="{height:banheight}">

            <div class="cell text-center" :style="{backgroundColor:banbackcol}" >
        
                
                    <div  v-html="banners[1].Html"></div>
            
            
            </div>
        </div>
 
</template>

<script>

    export default {
        name: "bannerad",
        data: function () {
            return {
                banners: this.bannertype
                
            }
        },
        props: {

            bannertype: Array,
            bannerdivheight: { type: String, default: '64px' },
            bannerbackcolour: {type: Boolean, default: false},
            actualbanbackcolour: {type: String, default: '#0d0638'}  
        },
        computed: {
                banbackcol() {
                   return this.bannerbackcolour == true ? banners[0].Html: this.actualbanbackcolour
                },
                banheight() {
                    return  this.banners[1] !== undefined ?  this.bannerdivheight   : 0
                }

        },
        created() {
          
           
           //alert(this.bannertype);
        }

    }


</script>